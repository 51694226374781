import '../css/style.css';

import React from 'react';
import Helmet from 'react-helmet';

import { Hero1 } from '../components/hero1';
import SEO from '../components/seo';

function NotFound() {
  return (
    <div>
      <SEO />
      <Helmet>
        <body className="font-sans antialiased bg-secondary-700" />
      </Helmet>
      <Hero1 />
      <div className="h-full bg-secondary-700">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <div className="w-full mx-auto">
            <h2 className="text-3xl font-extrabold leading-9 text-center text-gray-200 uppercase font-logo q-stroke-secondary sm:text-4xl sm:leading-10">
              Pagina niet gevonden
            </h2>
            <p className="block mt-3 text-lg leading-7 text-center text-gray-300">
              De pagina die u probeert te bezoeken is mogelijk verwijderd.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
