import classNames from "classnames";
import React, { useCallback } from "react";

export const EmailRenderer = ({ mail, className }) => {
  const atSplit = mail.split("@");
  const name = atSplit[0];
  const dotSplit = atSplit[1].split(".");
  const domain = dotSplit[0];
  const tld = dotSplit[1];
  const click = useCallback(() => {
    window.location.href = `mailto:${name}@${domain}.${tld}`;
    return false;
  }, [domain, name, tld]);

  return (
    <span
      className={classNames([className, "q-mail"])}
      data-name={name}
      data-domain={domain}
      data-tld={tld}
      onClick={click}
    />
  );
};
