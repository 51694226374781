import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Email } from "../icons/Email";
import { Location } from "../icons/Location";
import { Phone } from "../icons/Phone";
import { EmailRenderer } from "./email";
import { Logo } from "./logo";
import { Slideshow } from "./slideshow";

const query = graphql`
  query Hero {
    page: markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
      frontmatter {
        intro
        items {
          image
          alt
        }
        services_description
        services {
          name
          icon
        }
      }
    }
    contact: markdownRemark(fileAbsolutePath: { regex: "/contact.md/" }) {
      frontmatter {
        company_name
        image
        address
        address_link
        phone
        phone_link
        email
      }
    }
    menu: markdownRemark(fileAbsolutePath: { regex: "/menu.md/" }) {
      frontmatter {
        items {
          name
          url
        }
      }
    }
    images: allImageSharp {
      edges {
        node {
          fluid {
            srcSet
            originalName
          }
        }
      }
    }
  }
`;

export const Hero1 = () => {
  const data = useStaticQuery(query);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const images = data.page.frontmatter.items.map((item) => {
    const filename = item.image.replace("/images/uploads/", "");
    const edge = data.images.edges.filter((edge) => {
      return edge.node.fluid.originalName === filename;
    })[0];
    return edge.node.fluid.srcSet;
  });
  return (
    <AnimatePresence>
      <div className="relative overflow-hidden bg-white">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <div className="px-4 pt-6 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a className="flex items-center" href="/">
                      <Logo className="w-auto h-12" />
                      <div className="block pl-4 lg:hidden">
                        <h2 className="text-3xl font-extrabold leading-10 tracking-tight text-secondary-700 q-stroke-primary font-logo sm:text-4xl sm:leading-none md:text-5xl">
                          {data.contact.frontmatter.company_name}
                        </h2>
                      </div>
                    </a>
                    <div className="flex items-center -mr-2 md:hidden">
                      <button
                        onClick={toggleOpen}
                        type="button"
                        className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-secondary-700 hover:text-secondary-700 hover:bg-primary-500 focus:outline-none focus:bg-primary-500 focus:text-secondary-700"
                      >
                        <svg
                          className="w-6 h-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4">
                  {data.menu.frontmatter.items.map((item, i) => {
                    return (
                      <a
                        href={item.url}
                        className={classNames([
                          "font-medium transition duration-150 ease-in-out text-secondary-700 hover:text-gray-900 focus:outline-none focus:text-gray-900",
                          {
                            "ml-8": i !== 0,
                          },
                        ])}
                      >
                        {item.name}
                      </a>
                    );
                  })}
                </div>
              </nav>
            </div>
            {open && (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.175 }}
                className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
              >
                <div className="rounded-lg shadow-md">
                  <div className="overflow-hidden rounded-lg shadow-xs bg-secondary-700">
                    <div className="flex items-center justify-between px-5 pt-3 pb-3 bg-secondary-800">
                      <div className="flex items-center">
                        <span className="text-sm text-gray-300">Menu</span>
                      </div>
                      <div className="-mr-2">
                        <button
                          onClick={toggleOpen}
                          type="button"
                          className="inline-flex items-center justify-center p-2 text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-secondary-700 hover:bg-primary-500 focus:outline-none focus:bg-primary-500 focus:text-secondary-700"
                        >
                          <svg
                            className="w-6 h-6"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="px-2 pb-3">
                      {data.menu.frontmatter.items.map((item, i) => {
                        return (
                          <a
                            href={item.url}
                            className={classNames([
                              "block px-3 py-2 text-base font-medium transition duration-150 ease-in-out rounded-md text-primary-500 hover:text-white hover:bg-gray-50 focus:outline-none focus:text-white focus:bg-gray-50",
                            ])}
                          >
                            {item.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}

            <div className="max-w-screen-xl px-4 mx-auto sm:mt-4 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left ">
                <div className="hidden lg:block">
                  <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-secondary-700 q-stroke-primary font-logo sm:text-5xl sm:leading-none md:text-6xl">
                    {data.contact.frontmatter.company_name}
                  </h2>
                </div>
                <div className="flex flex-col justify-between w-full pt-4 lg:pt-2 sm:max-w-xl sm:mx-auto lg:mx-0 md:flex-row">
                  <a
                    className="flex content-center"
                    href={data.contact.frontmatter.phone_link}
                  >
                    <Phone className="w-auto h-6 p-1 rounded-full fill-current bg-secondary-700 text-primary-500" />
                    <span className="pl-2 text-sm font-bold text-secondary-700 xs:hidden md:inline-block">
                      {data.contact.frontmatter.phone}
                    </span>
                  </a>
                  <span className="flex content-center lg:ml-4">
                    <Email
                      className="w-auto h-6 p-1 rounded-full fill-current bg-secondary-700 text-primary-500"
                      size={20}
                    />
                    <EmailRenderer
                      className="pl-2 text-sm font-bold cursor-pointer text-secondary-700 xs:hidden md:inline-block"
                      mail={data.contact.frontmatter.email}
                    ></EmailRenderer>
                  </span>
                  <a
                    className="flex content-center lg:ml-4"
                    href={data.contact.frontmatter.address_link}
                  >
                    <Location
                      className="w-auto h-6 p-1 rounded-full fill-current bg-secondary-700 text-primary-500"
                      size={20}
                    />
                    <span className="pl-2 text-sm font-bold text-secondary-700 xs:hidden md:inline-block">
                      {data.contact.frontmatter.address}
                    </span>
                  </a>
                </div>
                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  {data.page.frontmatter.intro}
                </p>
              </div>
            </div>
            <svg
              className="absolute inset-y-0 right-0 hidden w-48 h-full text-white transform translate-x-1/2 lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-secondary-700">
          <Slideshow interval={3000} images={images} />
        </div>
      </div>
    </AnimatePresence>
  );
};
