import * as React from 'react';

export function Logo(props) {
  return (
    <svg viewBox="0 0 1402.68 1348.12" {...props}>
      <defs>
        <style>
          {".prefix__cls-1{fill:#354140}.prefix__cls-5{fill:#d3df47}"}
        </style>
      </defs>
      <title>{"logo"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__omtreklijn_figuur" data-name="omtreklijn figuur">
          <path
            className="prefix__cls-1"
            d="M8.76 675.36l692.65 660.06 692.65-660.06L701.41 15.3zm1016.6-1.13c0 297.74-145.12 539-324.21 538.86S376.84 971.47 376.81 673.73s145.11-539 324.2-538.86 324.31 241.61 324.35 539.36z"
          />
          <path
            d="M701.41 1340.94L3 675.36l3-2.9L701.41 9.77l698.45 665.59-3 2.89zM14.56 675.36l686.85 654.53 686.85-654.53L701.41 20.82zm686.75 541.73h-.16c-44.62 0-87.86-14.57-128.53-43.19-39.08-27.51-74.14-66.81-104.21-116.83-61.63-102.48-95.59-238.62-95.6-383.34s33.89-280.8 95.5-383.19c30.06-50 65.12-89.23 104.19-116.67 40.61-28.53 83.8-43 128.36-43h.14c44.62 0 87.86 14.57 128.53 43.19 39.08 27.51 74.14 66.81 104.22 116.83 61.63 102.48 95.58 238.62 95.59 383.34s-33.89 280.8-95.5 383.19c-30.06 50-65.11 89.22-104.18 116.67-40.61 28.53-83.8 43-128.35 43zm-.45-1078.22c-42.89 0-84.52 14-123.77 41.54-38.15 26.8-72.44 65.24-101.93 114.25-60.86 101.16-94.37 235.79-94.35 379.07 0 295.06 143.74 535.22 320.34 535.36h.15c42.89 0 84.53-14 123.77-41.55 38.15-26.8 72.44-65.23 101.93-114.24 60.86-101.17 94.37-235.79 94.35-379.07C1021.32 379.17 877.61 139 701 138.87z"
            fill="#e1de15"
          />
          <path
            stroke="#3a4345"
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={4}
            d="M1399.79 675.71l-698.07 668.05L2.89 674.91 700.96 6.86l698.83 668.85z"
          />
          <ellipse
            cx={700.86}
            cy={675.36}
            rx={317.99}
            ry={534.84}
            stroke="#33383b"
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={4}
          />
          <ellipse
            className="prefix__cls-5"
            cx={700.86}
            cy={1210.2}
            rx={142.02}
            ry={135.92}
          />
          <path
            className="prefix__cls-1"
            d="M700.86 1348.12c-79.41 0-144-61.87-144-137.92s64.61-137.92 144-137.92 144 61.87 144 137.92-64.58 137.92-144 137.92zm0-271.84c-77.21 0-140 60.07-140 133.92s62.81 133.92 140 133.92 140-60.07 140-133.92-62.79-133.92-140-133.92z"
          />
          <ellipse
            className="prefix__cls-5"
            cx={694.97}
            cy={137.92}
            rx={142.02}
            ry={135.92}
          />
          <path
            className="prefix__cls-1"
            d="M695 275.85c-79.41 0-144-61.87-144-137.93S615.56 0 695 0s144 61.87 144 137.92-64.61 137.93-144 137.93zM695 4c-77.24 0-140 60.08-140 133.92s62.81 133.93 140 133.93 140-60.08 140-133.93S772.18 4 695 4z"
          />
        </g>
      </g>
    </svg>
  );
}
