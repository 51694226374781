import * as React from 'react';

export function Phone(props) {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path d="M37.788 28.348l-4.086-4.088a4.685 4.685 0 00-6.625-.007l-.007.007-.833.833A90.61 90.61 0 0114.9 13.742l.833-.834a4.696 4.696 0 000-6.63l-4.095-4.083a4.803 4.803 0 00-6.638 0L2.767 4.438a6.635 6.635 0 00-.834 8.324 90.279 90.279 0 0025.289 25.293 6.69 6.69 0 008.333-.833l2.242-2.244a4.683 4.683 0 00.006-6.621l-.006-.007-.009-.002z" />
    </svg>
  )
}

