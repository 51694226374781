import * as React from 'react';

export function Email(props) {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path d="M39.813 9.72a.302.302 0 00-.333.065L23.235 26.027a4.584 4.584 0 01-6.48 0L.517 9.785a.3.3 0 00-.334-.065A.305.305 0 000 10v20a3.332 3.332 0 003.333 3.333h33.334A3.332 3.332 0 0040 30V10a.302.302 0 00-.187-.28zM18.525 24.26a2.087 2.087 0 002.947 0L37.615 8.117a.83.83 0 00.202-.852c-.184-.548-.694-.598-1.15-.598H3.333c-.458 0-.971.05-1.151.598a.83.83 0 00.201.852L18.525 24.26z" />
    </svg>
  )
}

