import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const Slideshow = ({ interval, images }) => {
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      let newIndex = current + 1;
      if (newIndex >= images.length) {
        newIndex = 0;
      }
      setCurrent(newIndex);
    }, interval);
  }, [current, images.length, interval]);
  return (
    <motion.img
      className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
      key={images[current]}
      srcSet={images[current]}
      alt=""
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.5 }}
    />
  );
};

Slideshow.propTypes = {
  interval: PropTypes.number,
  images: PropTypes.array,
};
