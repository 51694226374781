import * as React from 'react';

export function Location(props) {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path d="M20 0C12.64.008 6.675 5.973 6.667 13.333c0 5.85 8.333 20.042 11.913 25.874a1.667 1.667 0 002.84-.002C25 33.377 33.333 19.19 33.333 13.333 33.325 5.973 27.36.008 20 0zm0 19.167A5.833 5.833 0 1120 7.5a5.833 5.833 0 010 11.667z" />
    </svg>
  )
}

